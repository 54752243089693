import { handleResponse } from '@/services/handleresponse'
import { apiurl } from '@/services/config'
const API_URL = apiurl

export const StrukService = {
  addStruk,
  updateStruk,
  deleteStruk,
  getStruk,
  getByid,
  terTerminalist,
  getprodukterminal,
  getStrukFormat
}
function getStrukFormat (data) {
  return fetch(API_URL + '/api/strukformat?idpel=' + data.idpel + '&tgl=' + data.tgl + '&idtrx=' + data.idtrx, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  }).then(handleResponse)
    .then(data => {
      return data
    })
}
function addStruk (postdata) {
  return fetch(API_URL + '/api/struk', {
    method: 'POST',
    body: JSON.stringify({ postdata }.postdata),
    headers: {
      'Content-Type': 'application/json'
    }
  }).then(handleResponse)
    .then(data => {
      return data
    })
}
function getprodukterminal (cari) {
  return fetch(API_URL + '/api/terminal/produk?idterminal=' + cari.idterminal + '&search=' + cari.cari, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  }).then(handleResponse)
    .then(data => {
      return data
    })
}
function terTerminalist (cari) {
  return fetch(API_URL + '/api/terminal?search=' + cari, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  }).then(handleResponse)
    .then(data => {
      return data
    })
}

function getByid (id) {
  return fetch(API_URL + '/api/struk/' + id, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('token')
    }
  }).then(handleResponse)
    .then(data => {
      return data
    })
}

function getStruk (opt) {
  return fetch(API_URL + '/api/struk', {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('token')
    }
  }).then(handleResponse)
    .then(data => {
      return data
    })
}

function deleteStruk (id) {
  return fetch(API_URL + '/api/struk/' + id, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('token')
    }
  }).then(handleResponse)
    .then(data => {
      return data
    })
}
function updateStruk (postdata) {
  return fetch(API_URL + '/api/struk/' + postdata.id, {
    method: 'PUT',
    body: JSON.stringify({ postdata }.postdata),
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('token')
    }
  }).then(handleResponse)
    .then(data => {
      return data
    })
}
