<template>
  <v-app>
    <toolbar/>
    <v-main>
      <router-view/>
    </v-main>
    <v-snackbar :timeout="3000" bottom right :color="alert.color" v-model="alert.show">
      {{ alert.message }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="alert.show = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script>

import Toolbar from '@/components/toolbar'
import { mapActions, mapState } from 'vuex'
export default {
  name: 'App',
  components: { Toolbar },
  data: () => ({
    expanded: true,
    snackbar: {
      show: true,
      text: '',
      color: ''
    },
    token: localStorage.getItem('token')
  }),
  computed: {
    ...mapState({
      alert: state => state.alert
    })
  },
  methods: {
    ...mapActions({
      clearAlert: 'alert/clear',
      closedialogutama: 'alert/closedialogutama'
    }),
    closedialog () {
      this.closedialogutama()
    }
  }
}
</script>
