<template>
    <v-container class="fill-height">
      <v-layout row wrap>
        <v-flex lg9>
          <div class="fill-height">
            <div class="pa-2">
              <div v-if="!showstruk">
                <h2>Selamat Datang</h2>
                <p>Pastikan printer sudah terpasang dengan baik., untuk memulai Cetak struk Pembayaran klik pencarian data disamping. </p>
              </div>
              <div v-if="showstruk" class="pa-2 rounded" style="border: thin solid rgba(0,0,0,.12)">
                <pre id="hasil">
                  {{strukformat}}
                </pre>
              </div>
            </div>
          </div>
        </v-flex>
        <v-flex lg3>
          <div class="border_all ma-2" style="border: thin solid rgba(0,0,0,.12)">
            <v-toolbar elevation="0" color="primary" dark text dense cad>
              <v-toolbar-title class="subheading">Cari Data</v-toolbar-title>
            </v-toolbar>
            <div class="pa-4">
              <div class="mt-0">
                <v-menu
                  v-model="menu1"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="tgltrx"
                      label="Tanggal"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="tgltrx" @input="menu1 = false"></v-date-picker>
                </v-menu>
              </div>
              <v-text-field v-model="idpel" label="Id Pelanggan"></v-text-field>
              <div class="text-center">
                <v-btn @click="caridata" text class="primary"><v-icon>mdi-magnify</v-icon>Cari</v-btn>
                <v-btn v-if="showstruk" @click="popup" text class="primary ml-2"><v-icon>mdi-printer</v-icon>Cetak</v-btn>
              </div>
            </div>
          </div>
        </v-flex>
      </v-layout>
    </v-container>
</template>

<script>
import moment from 'moment'
import { mapActions, mapState } from 'vuex'
export default {
  name: 'cetakstruk',
  data: () => ({
    idpel: '',
    kodeproduk: 'TOKEN',
    menu1: false,
    msgcetak: '\nBANK SYARIAH MANDIRI\n' +
      'STRUK PEMBELIAN LISTRIK PRABAYAR\n' +
      '\n' +
      'TANGGAL\t\t:2021/10/19 16:50:56\n' +
      'NO METER\t:45055020940\t\t\t\t\tADMIN BANK\t: Rp.2000\n' +
      'ID PEL\t\t:45055020940\t\t\t\t\tMATERAI\t\t: RP.       0\n' +
      'NAMA\t\t:I GUSTI KETUT-SUDARMA    \t\tPPN\t\t: Rp.       0\n' +
      'TARIF/DAYA\t:R1/1300   \t\t\t\t\t\tPPJ\t\t: Rp.       0\n' +
      'REF\t\t:f0b16580c18313250144ec42256e3331\tANGSURAN\t: Rp.       0\n' +
      'RP BAYAR\t:RP 22000\t\t\t\t\t\tRP TOKEN\t: Rp.       0\n' +
      '\t\t\t\t\t\t\t\t\t\tJML KWH\t\t: 12,8\n' +
      'STROOM/TOKEN\t:2571-8444-3112-5626-9088\n' +
      '\t\t\t\tInformasi Hub Call Center 123\n' +
      '\t\t\t\t\t    atau\n' +
      '\t\t\t\t     Hub PLN Terdekat',
    date: new Date().toISOString().substr(0, 10),
    tgltrx: moment().add(7, 'hours').toISOString().substr(0, 10),
    statuses: [
      {
        id: 'TOKEN',
        label: 'PLN PREPAID'
      },
      {
        id: 1,
        label: 'PLN POSTPAID'
      },
      {
        id: 2,
        label: 'TELKOM'
      }
    ]
  }),
  computed: {
    ...mapState('StrukStore', ['strukformat', 'showstruk'])
  },
  methods: {
    ...mapActions('StrukStore', ['getStrukFormat']),
    caridata () {
      this.getStrukFormat({ idpel: this.idpel, tgl: this.tgltrx, idtrx: '' })
    },
    popup () {
      const datacetak = this.strukformat
      var left = (screen.width / 2) - (700 / 2)
      var top = (screen.height / 2) - (300 / 2)
      var mywindow = window.open('', 'my div', 'top=' + top + ', left=' + left + 'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no,height=300,width=700')
      //  mywindow.document.write('<html><head><title>Print Struk</title>');
      mywindow.document.write('<html lang="id"><head><title>CetakStruk</title></head><body style="margin: 0;">')
      // mywindow.document.write('</head><body>');
      mywindow.document.write('<pre style="border:none;background-color:#fff">' + datacetak + '</pre>')
      mywindow.document.write('</BODY></html>')

      mywindow.document.close()
      mywindow.focus()

      mywindow.print()
      mywindow.close()

      return true
    }
  }
}
</script>

<style scoped>

</style>
