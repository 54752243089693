<template>
  <v-app-bar
    app dense
    color="primary"
    dark>
    <div class="d-flex align-center">
    </div>
    <h2>Cetak Struk</h2>
    <v-spacer></v-spacer>
  </v-app-bar>
</template>

<script>
export default {
  name: 'toolbar'
}
</script>

<style scoped>

</style>
