<template>
  <div class="ma-6" style="border: thin solid rgba(0,0,0,.12)">
    <v-toolbar elevation="0">
      <v-text-field
        flat
        solo
        prepend-icon="mdi-card-search"
        placeholder="Type something"
        v-model="opsitable.search"
        hide-details
        class="hidden-sm-and-down"
      ></v-text-field>
      <v-spacer></v-spacer>
      <v-btn small elevation="0" class="ml-2" color="primary" @click.native="gotonew">
        <v-icon dark>mdi-plus</v-icon>Tambah
      </v-btn>
      <v-btn small elevation="0" class="ml-2" color="primary" @click.native="getstruklist(opsitable)">
        <v-icon dark>mdi-refresh</v-icon>Refresh
      </v-btn>
    </v-toolbar>
    <v-data-table
      :headers="headers"
      :search="opsitable.search"
      :items="struk"
      class="elevation-0"
      item-key="id"
      :options.sync="opsitable"
      :server-items-length="totalstruk"
      :loading="loading"
      @update:options="getstruklist(opsitable)">
      <template v-slot:item.action="{ item }">
        <div class="ma-4 text-xs-left">
          <v-btn x-small fab text @click.native="openedit(item)" class="primary">
            <v-icon small>mdi-square-edit-outline</v-icon>
          </v-btn>
          <v-btn x-small fab text @click.native="deleteconfm(item)" dark class="red ml-2">
            <v-icon small>mdi-delete</v-icon>
          </v-btn>
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import Utils from '@/plugins/utils'

export default {
  name: 'StrukList',
  data: () => ({
    opsitable: {
      search: '',
      itemsPerPage: 10,
      page: 1
    },
    statuses: [
      {
        id: -1,
        label: 'Semua'
      },
      {
        id: 0,
        label: 'Pending'
      },
      {
        id: 1,
        label: 'Sukses'
      },
      {
        id: 2,
        label: 'Gagal'
      }
    ],
    headers: [
      {
        text: 'IdTrx',
        value: 'id'
      },
      {
        text: 'Terminal',
        value: 'namaterminal'
      },
      {
        text: 'KodeProduk',
        value: 'kodeproduk'
      },
      {
        text: 'Harga',
        value: 'pattern'
      },
      {
        text: 'Aksi',
        value: 'action'
      }
    ]
  }),
  computed: {
    ...mapState('StrukStore', ['struk', 'totalstruk', 'loading'])
  },
  methods: {
    ...mapActions('StrukStore', ['getstruklist', 'deleteStruk']),
    gotonew () {
      this.$router.push('/setupstruk/new')
    },
    formatAngka (val) {
      return Utils.formatAngka(val)
    },
    openedit (data) {
      this.$router.push('/setupstruk/edit/' + data.id)
    },
    deleteconfm (data) {
      console.log(data)
      this.$confirm('HapusStruk ?', { color: 'red', title: 'Konfirmasi', icon: 'fa-question' }).then(res => {
        if (res) {
          this.deleteStruk(data.id)
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
