
export function handleResponse (response) {
  return response.text().then(text => {
    const data = text && JSON.parse(text)

    if (!data.success) {
      if (response.status === 401) {
        // logout()
        console.log('CALL LOGOUT')
        localStorage.setItem('token', null)
        localStorage.clear()
        location.reload(true)
      }

      const error = (data && data.msg) || response.statusText || response.status
      return Promise.reject(error)
    }

    return data
  })
}
