import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import colors from 'vuetify/lib/util/colors'
import 'vuetify/dist/vuetify.min.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import VuetifyConfirmPlugin from 'vuetify-confirm'

Vue.use(Vuetify)

const vuetify = new Vuetify({
  icons: {
    iconfont: 'md' || 'fa'
  },
  theme: {
    dark: false,
    themes: {
      light: {
        primary: '#128c7e',
        secondary: colors.grey.darken1,
        accent: colors.shades.black,
        error: colors.red.accent3
      }
    }
  }
})
Vue.use(VuetifyConfirmPlugin, {
  vuetify,
  buttonTrueText: 'Yes',
  buttonFalseText: 'No',
  color: 'warning',
  icon: 'warning',
  title: 'Warning',
  width: 350,
  persistent: false,
  property: '$confirm'
})
export default vuetify
