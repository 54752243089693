import Vue from 'vue'
import Vuex from 'vuex'
import { StrukStore } from '@/store/struk.store'
import { alert } from '@/store/alert.module'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    alert,
    StrukStore
  }
})
