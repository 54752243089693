<template>
  <div class="ma-8">
    <h2>Buat Format Struk</h2>
    <v-row class="ml-1 mt-2">
      <v-col cols="12" lg="6">
        <v-row>
          <v-autocomplete
            single-line
            label="Terminal"
            placeholder="Pilih Terminal"
            item-text="namaterminal"
            item-value="idterminal"
            :loading="loading"
            @change="terminalchange"
            v-model="newdata.idterminal"
            :items="terminals">
          </v-autocomplete>
          <v-autocomplete
            label="Produk"
            single-line
            placeholder="Pilih Produk"
            item-text="namaproduk"
            item-value="idproduk"
            v-model="newdata.idproduk"
            :items="produkterminal">
          </v-autocomplete>
          <v-btn text @click="simpan" class="primary mt-3 ml-2">Simpan</v-btn>
          <v-btn dark text @click="generatetemplatestruk" class="primary mt-3 ml-2">Generate</v-btn>
          <v-btn dark text @click="kembali" class="primary mt-3 ml-2">Kembali</v-btn>
        </v-row>
      </v-col>
    </v-row>
    <div class="mt-2">
      <v-textarea  outlined label="Replay/ balasan" v-model="sourcetext" rows="3"></v-textarea>
      <v-textarea :error="!regexOK" v-model="newdata.pattern" outlined label="Pattern" rows="2"></v-textarea>
      <div class="ml-6 mr-6 mb-8 mt-4">
        <v-row>
          <div v-for="(item,index) in grouptext" :key="index">
            <v-chip pill  draggable class="pa-2 ma-1 primary">{{item.name}}={{item.value}}</v-chip>
          </div>
        </v-row>
      </div>
      <v-textarea @keydown.tab.prevent="tabber($event)"  v-model="newdata.struk" outlined label="Format Struk" rows="4"></v-textarea>
    </div>
    <div>

    </div>
    <div class="rounded pa-2 mt-2" style="border: thin solid rgba(0,0,0,.12)">
      <pre>
        {{hasilstruk}}
      </pre>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import pad from 'utils-pad-string'
import S from 'string'
import moment from 'moment'
import Utils from '@/plugins/utils'
export default {
  name: 'NewStruk',
  data: () => ({
    new: true,
    state: 0,
    loading: false,
    newdata: {
      idterminal: -1,
      idproduk: -1,
      pattern: '',
      struk: ''
    },
    sourcetext: '',
    ffformatstruk: '<namars>\n' +
      '<alamatrs>\n' +
      '<phoners>\n' +
      '===================================\n' +
      'STRUK PEMBAYARAN TELKOM\n' +
      '\n' +
      'Waktu\t\t: <waktu> \n' +
      'IdPel\t\t: <idpel>\n' +
      'Nama\t\t: <nama>\n' +
      'Bln\t\t\t: <bln>\n' +
      'reff\t\t: <reff>\n' +
      'RpTag\t\t: <rptag>\n' +
      'Admin\t\t: <admin>\n' +
      '------------------------- +\n' +
      'TotalBayar\t: <total>\n' +
      '\n' +
      'Simpan Struk ini sebagai bukti yang sah\n' +
      'TERIMAKASIH\t',
    hasilstruk: '\nPREVIEW STRUK  ',
    terminal: [{ idterminal: 10, namaterminal: 'PPOB QPAY' }],
    produk: [{ idproduk: 1, namaproduk: 'PLN POSTPAID' }],
    regexOK: false,
    namedgroup: [],
    grouptext: []
  }),
  mounted () {
    this.getTerminal('')
    if (!this.$route.params.id) {
      this.state = 0 // new
      // this.newdata.struk=this.ffformatstruk
      this.hasilstruk = this.formatlocal(this.newdata.struk)
      this.parseAll()
    } else {
      this.state = 1 // edit
      this.getStrukbyid(this.$route.params.id)
    }
  },
  watch: {
    ffformatstruk: function (val) {
      this.getNamedGroup()
      var regex = new RegExp(val.pattern, 'g')
      var match = regex.exec(this.sourcetext)
      this.hasilstruk = this.formatlocal(this.newdata.struk)
      this.hasilstruk = this.parsematch(match)
    },
    strukedit: function (val) {
      console.log(val)
      this.newdata = val
      this.getProdukTerminal({ idterminal: val.idterminal, cari: '' })
    },
    sourcetext: function () {
      this.parseAll()
    },
    pat: function () {
      this.parseAll()
    },
    fstruk: function () {
      this.parseAll()
    }
  },
  computed: {
    ...mapState('StrukStore', ['terminals', 'produkterminal', 'strukedit']),
    pat: function () {
      return this.newdata.pattern
    },
    fstruk: function () {
      return this.newdata.struk
    }
  },
  methods: {
    ...mapActions('StrukStore', ['getTerminal', 'getProdukTerminal', 'addStruk', 'editStruk', 'getStrukbyid']),
    parseAll () {
      this.getNamedGroup()
      try {
        var regex = new RegExp(this.newdata.pattern, 'g')
        var match = regex.exec(this.sourcetext)
        this.hasilstruk = this.formatlocal(this.newdata.struk)
        this.hasilstruk = '\n' + this.parsematch(match).trim()
      } catch (e) {
        // console.log(e)
      }
    },
    tabber (event) {
      if (event) {
        event.preventDefault()
        const startText = this.newdata.struk.slice(0, event.target.selectionStart)
        const endText = this.newdata.struk.slice(event.target.selectionStart)
        this.newdata.struk = `${startText}\t${endText}`
        event.target.selectionEnd = event.target.selectionStart + 1
      }
    },
    getNamedGroup () {
      this.namedgroup = []
      var regex = /\(\?<([a-zA-Z][a-zA-Z0-9]*)>/g
      var match = regex.exec(this.newdata.pattern)
      while (match != null) {
        this.namedgroup.push(match[1])
        match = regex.exec(this.newdata.pattern)
      }
    },
    parsematch (match) {
      let a = this.hasilstruk
      const jasaloket = 2000
      const denom = 100000
      const sn = '02102020203123213'
      a = a.replace('<jasaloket>', 'Rp' + pad(this.formatAngka(jasaloket), 10, { lpad: '.' }))
      a = a.replace('<denom>', 'Rp' + pad(this.formatAngka(denom), 10, { lpad: '.' }))
      this.grouptext = []
      if (match != null) {
        this.regexOK = true
        for (let i = 0; i < this.namedgroup.length; i++) {
          this.grouptext.push({ name: '<' + this.namedgroup[i] + '>', value: match.groups[this.namedgroup[i]].trim() })
          if (S(this.namedgroup[i]).startsWith('rp') || S(this.namedgroup[i]).contains('totalbayar') || S(this.namedgroup[i]).contains('total') || S(this.namedgroup[i]).contains('admin') || S(this.namedgroup[i]).contains('tag')) {
            a = a.replace('<' + this.namedgroup[i] + '>', 'Rp' + pad(this.formatAngka(match.groups[this.namedgroup[i]].trim()), 10, { lpad: '.' }))
          } else {
            a = a.replace('<' + this.namedgroup[i] + '>', match.groups[this.namedgroup[i]].trim())
          }
        }
      } else {
        this.regexOK = false
      }
      const x = denom + jasaloket
      a = a.replace('<denom+jasaloket>', 'Rp' + pad(this.formatAngka(x), 10, { lpad: '.' }))
      a = a.replace('<sn>', sn)
      a = a.replace('<idrs>', 'ID0001')
      a = a.replace('<namars>', 'TEST NAMA')
      a = a.replace('<alamatrs>', 'Jln Maju Makmur No 10,Denpasar BALI')
      a = a.replace('<phoners>', '081xxxx9999')
      return a
    },
    formatDate (value) {
      return moment(String(value)).format('DD/MM/YYYY HH:mm:ss')
    },
    formatlocal (input) {
      const dt = moment().format('DD/MM/YYYY HH:mm:ss')
      return input.replace('<waktu>', dt)
    },
    formatAngka (val) {
      return Utils.formatAngka(val)
    },
    generatetemplatestruk () {
      this.$confirm('Generate Contoh Struk ?', { color: 'primary', title: 'Konfirmasi', icon: 'fa-question' }).then(res => {
        if (res) {
          this.newdata.struk = this.ffformatstruk
        }
      })
    },
    terminalchange (val) {
      console.log(val)
      this.getProdukTerminal({ idterminal: val, cari: '' })
    },
    kembali () {
      this.$router.push('/setupstruk')
    },
    simpan () {
      this.$confirm('Simpan Struk ?', { color: 'primary', title: 'Konfirmasi', icon: 'fa-question' }).then(res => {
        if (res) {
          if (this.state === 1) {
            this.editStruk(this.newdata)
          } else {
            this.addStruk(this.newdata)
          }
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
