<template>
  <cetakstruk/>
</template>

<script>
import Cetakstruk from '@/components/cetakstruk'

export default {
  name: 'Home',

  components: {
    Cetakstruk
  }
}
</script>
