
import { StrukService } from '@/services/struk.service'

const state = {
  loading: false,
  dialog: false,
  overlay: false,
  struk: [],
  totalstruk: 0,
  strukdata: {},
  terminals: [],
  produkterminal: [],
  strukformat: '',
  showstruk: false,
  strukedit: { id: 1 }
}
const actions = {
  setloading ({ commit }, data) {
    commit('setloading', data)
  },
  setshowstruk ({ commit }, show) {
    commit('setshowstruk', show)
  },
  setdialog ({ commit }, diag) {
    commit('setdialog', diag)
  },
  getstruklist ({ dispatch, commit }, opt) {
    commit('setloading', true)
    StrukService.getStruk(opt).then(value => {
      commit('setdata', value)
      commit('setloading', false)
    }).catch(reason => {
      dispatch('alert/error', reason, { root: true })
      commit('setloading', false)
    })
  },
  getStrukbyid ({ dispatch, commit }, id) {
    commit('setloading', true)
    StrukService.getByid(id).then(value => {
      commit('setstrukedit', value)
      commit('setloading', false)
    }).catch(reason => {
      dispatch('alert/error', reason, { root: true })
      commit('setloading', false)
    })
  },
  addStruk ({ dispatch, commit }, opt) {
    commit('setloading', true)
    StrukService.addStruk(opt).then(value => {
      commit('setloading', false)
      commit('setdialog', false)
      dispatch('alert/success', value.msg, { root: true })
    }).catch(e => {
      dispatch('alert/error', e, { root: true })
      commit('setloading', false)
    })
  },
  editStruk ({ dispatch, commit }, opt) {
    commit('setloading', true)
    StrukService.updateStruk(opt).then(value => {
      commit('setloading', false)
      commit('setdialog', false)
      dispatch('alert/success', value.msg, { root: true })
    }).catch(e => {
      dispatch('alert/error', e, { root: true })
      commit('setloading', false)
    })
  },
  deleteStruk ({ dispatch, commit }, id) {
    commit('setloading', true)
    StrukService.deleteStruk(id).then(value => {
      commit('setloading', false)
      commit('setdialog', false)
      dispatch('alert/success', value.msg, { root: true })
      StrukService.getStruk({}).then(value => {
        commit('setdata', value)
        commit('setloading', false)
      }).catch(reason => {
        dispatch('alert/error', reason, { root: true })
        commit('setloading', false)
      })
    }).catch(e => {
      dispatch('alert/error', e, { root: true })
      commit('setloading', false)
    })
  },
  getProdukTerminal ({ dispatch, commit }, cari) {
    commit('setloading', true)
    StrukService.getprodukterminal(cari).then(value => {
      commit('setloading', false)
      commit('setdialog', false)
      commit('setprodukterminal', value)
    }).catch(e => {
      dispatch('alert/error', e, { root: true })
      commit('setloading', false)
    })
  },
  getStrukFormat ({ dispatch, commit }, data) {
    commit('setloading', true)
    StrukService.getStrukFormat(data).then(value => {
      commit('setloading', false)
      commit('setdialog', false)
      commit('setstrukformat', value)
    }).catch(e => {
      dispatch('alert/error', e, { root: true })
      commit('setloading', false)
    })
  },
  getTerminal ({ dispatch, commit }, cari) {
    commit('setloading', true)
    StrukService.terTerminalist(cari).then(value => {
      commit('setloading', false)
      commit('setdialog', false)
      commit('setterminal', value)
    }).catch(e => {
      dispatch('alert/error', e, { root: true })
      commit('setloading', false)
    })
  }
}
const mutations = {
  setloading (state, s) {
    state.loading = s
  },
  setdialog (state, s) {
    state.dialog = s
  },
  setdata (state, s) {
    state.struk = []
    state.struk = s.data
    state.totalstruk = s.data.length
    state.loading = false
    state.overlay = false
  },
  setterminal (state, s) {
    state.terminals = []
    state.terminals = s.data
    state.loading = false
    state.overlay = false
  },
  setprodukterminal (state, s) {
    state.produkterminal = []
    state.produkterminal = s.data
    state.loading = false
    state.overlay = false
  },
  setstruk (state, s) {
    state.strukdata = []
    state.strukdata = s.data
    state.loading = false
    state.overlay = false
  },
  setshowstruk (state, s) {
    state.showstruk = s
  },
  setstrukedit (state, s) {
    state.strukedit = {}
    state.strukedit = s.data
    state.loading = false
    state.overlay = false
  },
  setstrukformat (state, s) {
    state.strukformat = ''
    state.strukformat = s.data.msg
    state.showstruk = true
    state.loading = false
    state.overlay = false
  }
}

export const StrukStore = {
  namespaced: true,
  state,
  actions,
  mutations
}
